.dataset-list table {
    text-align: left;
    border-collapse: collapse;
    margin: auto;
    width: auto;
}

.dataset-list thead {
    border-bottom: 1px solid #EEEEEE;
}

.dataset-list tbody tr:nth-child(even) {
    background-color: #EEEEEE;
}

.dataset-list tbody i {
    padding: .25rem;
}

.dataset-list th, .dataset-list td {
    padding: 0.5em 2rem;
}

@media screen and (max-width: 768px) {
    .dataset-list table {
        width: 100%;
    }

    .dataset-list thead {
        display: none;
    }

    .dataset-list tbody tr {
        display: flex;
        flex-direction: column;
    }

    .dataset-list tbody .dataset-list-label::before {
        content: "Datová sata: ";
        font-weight: bold;
    }

    .dataset-list tbody .dataset-list-source::before {
        content: "Zdroj: ";
        font-weight: bold;
    }

    .dataset-list tbody .dataset-list-update::before {
        content: "Aktualizace: ";
        font-weight: bold;
    }
}