.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header img {
    margin: 2rem;
    width: 100%;
    max-width: 600px;
}

.header .header-logo-large {
    display: block;
}

.header .header-logo-small {
    display: none;
}

@media screen and (max-width: 768px) {

    .header .header-logo-large {
        display: none;
    }

    .header .header-logo-small {
        display: block;
        padding: 0 2rem;
    }
}