@charset "UTF-8";
/** Variables */
/**
    breakpoints:
    sm: 576px;
    md: 768px;
    lg: 992px;
    xl: 1200px;
*/
:root {
    --font: "Segoe UI", "Open Sans", sans-serif;
    --fontExtra: "Righteous", "Segoe UI", "Open Sans", sans-serif;
    --font-bold: 700;
    --font-semibold: 600;
    --font-normal: 400;
    --h1-font-size: 2rem;
    --h2-font-size: 1.25rem;
    --lh: 1.4;
    --card-title-size: 1.25rem;
    --primary-color: #6366f1;
    --secondary-color: #767676;
    --success-color: #1da750;
    --danger-color: #d9342b;
    /**--background-color: #F1F2F1;*/
    --background-color: #fff;
    --card-background-color: #ffffff;
    --text-color: black;
    --grid-gutter: 0.5rem;
    --grid-col-count: 12;
    --col-1: calc(1 / var(--grid-col-count) * 100%);
    --col-2: calc(2 / var(--grid-col-count) * 100%);
    --col-3: calc(3 / var(--grid-col-count) * 100%);
    --col-4: calc(4 / var(--grid-col-count) * 100%);
    --col-5: calc(5 / var(--grid-col-count) * 100%);
    --col-6: calc(6 / var(--grid-col-count) * 100%);
    --col-7: calc(7 / var(--grid-col-count) * 100%);
    --col-8: calc(8 / var(--grid-col-count) * 100%);
    --col-9: calc(9 / var(--grid-col-count) * 100%);
    --col-10: calc(10 / var(--grid-col-count) * 100%);
    --col-11: calc(11 / var(--grid-col-count) * 100%);
    --col-12: calc(12 / var(--grid-col-count) * 100%);
    --p-2: 0.5rem;
    --p-3: 1rem;
    --card-hover-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1), 0px 3px 3px -3px rgba(0, 0, 0, 0.12), 0px 4px 6px -3px rgba(0, 0, 0, 0.2);
    /**--card-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.07);*/
    --card-border: 0.75px solid #dee2e6;
    --card-radius: 4px;
    --text-hover-shadow: 0px 0px 0.75px rgba(0, 0, 0, 0.25);
    --menu-item-hover: brightness(97%);
    /** chart styles */
    --chart-labels-font: var(--font);
    --chart-labels-size: 14px;
    --chart-grid-color: var(--background-color);
    --chart-labels-color: #000000;
    --chart-ticks-color: var(--secondary-color);
    /** Pro */
    --clr-1: #b3eeb3;
    /** Proti */
    --clr-2: #ffb3b3;
    /** Zdržel se */
    --clr-3: #ffffb3;
    /** Nehlasoval */
    --clr-4: #c2c2c2;
    /** Nepřítomen */
    --clr-5: #d9d9d9;
    /** Tajná */
    --clr-6: #e8e8e8;
    /** Omluven */
    --clr-7: #5f5f5f;
    /** menu */
    --menu-height: 70px;
    --menu-image-height: 65px;
    --menu-image-display: none;
    /**sm */
    --layout-gap: 1rem;
    --body-top-padding: 1rem;
    --body-bottom-padding: 1rem;
    --body-side-padding: 1rem;
    --body-padding: var(--body-top-padding) var(--body-side-padding) var(--body-bottom-padding);
    --card-padding: 0.5rem;
    --card-body-padding: 0.25rem;
    --card-footer-padding: 0rem;
    /** md */
    /** lg */
    /** xl */
}
@media screen and (min-width: 768px) {
    :root {
        --layout-gap: 1.5rem;
        --body-top-padding: 1rem;
        --body-side-padding: 1.5rem;
        --body-bottom-padding: 1.5rem;
        --card-padding: 1rem;
        --menu-image-display: block;
        --h1-font-size: 3rem;
        --h2-font-size: 1.5rem;
        --card-title-size: 1.5rem;
    }
}
@media screen and (min-width: 992px) {
    :root {
        --card-body-padding: 0.25rem;
        --layout-gap: 1.5rem;
        --body-top-padding: 2rem;
        --body-side-padding: 4.5rem;
        --body-bottom-padding: 2rem;
    }
}
@media screen and (min-width: 1200px) {
    :root {
        --body-top-padding: 2rem;
        --body-side-padding: 6rem;
        --body-bottom-padding: 2rem;
    }
}

/**************/
.p-component {
    font-family: var(--font);
}

* {
    box-sizing: border-box;
}

html {
    line-height: var(--lh);
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: var(--font);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--background-color);
}

#root {
    position: relative;
    min-height: calc(100vh - var(--menu-height));
    min-width: 375px;
    width: var(--col-12);
}

#body {
    padding: var(--body-padding);
}

a {
    text-decoration: none;
    color: var(--primary-color);
}

a:hover {
    text-shadow: var(--text-hover-shadow);
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -ms-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    -webkit-filter: none;
    filter: none;
}

a:hover h2 {
    filter: brightness(60%);
    -webkit-filter: brightness(60%);
}

h1 {
    color: var(--primary-color);
    text-align: center;
    font-size: var(--h1-font-size);
    font-weight: var(--font-bold);
    text-wrap: pretty;
    text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1);
    line-height: 1;
}

h2 {
    font-size: var(--h2-font-size);
    color: var(--primary-color);
    line-height: var(--lh);
}

h4 {
    color: var(--primary-color);
}

h3 {
    margin: 0.5rem 0;
    color: var(--primary-color);
}

.subtitle {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

p {
    color: var(--text-color);
}

button {
    border: none;
    background: none;
    padding: 0;
    text-align: left;
    cursor: pointer;
}

.aliases-badge {
    margin-left: 0.2rem;
    vertical-align: top;
}

.aliases-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

/** Icons */
.pi {
    font-family: "primeicons";
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    font-size: large;
}

.bi {
    font-family: "primeicons";
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    font-size: large;
}

h1 .bi-info-circle {
    padding: 0 0.3rem;
    vertical-align: super;
}

.bi-info-circle {
    cursor: pointer;
}

.bi-check-square {
    color: var(--success-color);
}

.bi-x-square {
    color: var(--danger-color);
}

.bi-dash-square {
    color: var(--secondary-color);
}

.bi-filetype-json {
    font-size: 4rem;
    color: var(--primary-color);
    font-weight: var(--font-semibold);
}

.pi-times:hover {
    cursor: pointer;
}

.text-icon__left {
    margin-right: 0.5rem;
}

.text-icon__right {
    margin-left: 0.5rem;
}

/** footer */
.footer {
    position: absolute;
    bottom: 0;
    height: 58px;
    left: 0;
    width: var(--col-12);
    transform: translateY(100%);
}

.footer-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 2rem;
    margin-right: 2rem;
}

.footer-text {
    line-height: 2;
    color: var(--secondary-color);
}

.footer-text-warn{
    color: #cc8925;
}

.p-inline-message-warn{
    width:100%;
}

/** Home.js */
.overview .overview-title__year {
    color: var(--primary-color);
    white-space: nowrap;
}
.overview .overview-grid {
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(3, auto);
    gap: var(--layout-gap);
}

.overview .mandates-card {
    grid-area: 1/1/3/16;
}
.overview .mandates-card .grid {
    justify-content: center;
}
.overview .mandates-card .grid > div {
    flex-basis: content;
    padding: 8px 2rem;
    justify-content: center;
}
.overview .mandates-card .grid .item-svg {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--grid-gutter);
    width: var(--col-12);
}
@media screen and (min-width: 1400px) {
    .overview .mandates-card .grid .item-svg {
        width: var(--col-6);
    }
}
.overview .mandates-card .grid .item-svg svg > g {
    cursor: pointer;
}
.overview .mandates-card .grid .item-svg svg:hover > g {
    opacity: 0.5;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}
.overview .mandates-card .grid .item-svg svg:hover > g:hover {
    opacity: 1;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}
.overview .mandates-card .grid .item-list {
    width: var(--col-12);
    display: flex;
    flex-flow: column;
}
@media screen and (min-width: 1400px) {
    .overview .mandates-card .grid .item-list {
        width: var(--col-6);
    }
}
.overview .mandates-card .grid .item-list > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.overview .mandates-card .grid .item-list > div > span {
    margin-left: 3rem;
}
.overview .recent-meetings-card {
    grid-area: 1/16/2/21;
}
.overview .members-card {
    grid-area: 2/16/3/21;
}
.overview .votings-card {
    grid-area: 3/1/3/16;
}
.overview .votings-card .grid .item-text {
    width: var(--col-12);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    text-align: center;
}
.overview .votings-card .grid .item-text > div {
    padding: 0.5rem 0.5rem;
}
@media screen and (min-width: 768px) {
    .overview .votings-card .grid .item-text {
        width: var(--col-3);
        justify-content: space-evenly;
        flex-direction: column;
        text-align: center;
    }
}
.overview .votings-card .grid .item-chart {
    width: var(--col-12);
}
@media screen and (min-width: 768px) {
    .overview .votings-card .grid .item-chart {
        width: var(--col-9);
    }
}
.overview .analyses-card {
    grid-area: 3/16/3/21;
    display: flex;
}
.overview .analyses-card .p-card {
    flex-grow: 1;
}
@media screen and (max-width: 1200px) {
    .overview .overview-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, auto);
        gap: var(--layout-gap);
    }
    .overview .mandates-card {
        grid-area: 1/1/2/3;
    }
    .overview .recent-meetings-card {
        grid-area: 2/1/3/2;
    }
    .overview .members-card {
        grid-area: 2/2/3/3;
    }
    .overview .votings-card {
        grid-area: 3/1/3/3;
    }
    .overview .analyses-card {
        grid-area: 4/1/4/3;
    }
}
@media screen and (max-width: 768px) {
    .overview .overview-grid {
        display: flex;
        flex-direction: column;
    }
}

/** Votings.js */
.votings-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(5, auto);
    gap: var(--layout-gap);
}
.votings-grid .summary-card {
    grid-area: 1/1/2/3;
}
.votings-grid .summary-card .p-card-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-grow: 1;
}
.votings-grid .summary-card .p-card-content > div {
    text-align: center;
    width: var(--col-6);
}
@media (min-width: 576px) and (max-width: 1200px) {
    .votings-grid .summary-card .p-card-content {
        flex-wrap: nowrap;
    }
}
.votings-grid .chart-card {
    grid-area: 1/3/2/8;
}
.votings-grid .chart-card .p-dropdown {
    width: 170px;
}
.votings-grid .table-card {
    grid-area: 2/1/6/8;
}
.votings-grid .table-card .grid .item-options {
    width: var(--col-12);
}
@media screen and (min-width: 1200px) {
    .votings-grid .table-card .grid .item-options {
        width: var(--col-3);
    }
}
.votings-grid .table-card .grid .item-options .grid .item-dropdown,
.votings-grid .table-card .grid .item-options .grid .item-filter {
    width: var(--col-12);
}
@media screen and (min-width: 768px) {
    .votings-grid .table-card .grid .item-options .grid .item-dropdown,
    .votings-grid .table-card .grid .item-options .grid .item-filter {
        width: var(--col-6);
    }
}
@media screen and (min-width: 1200px) {
    .votings-grid .table-card .grid .item-options .grid .item-dropdown,
    .votings-grid .table-card .grid .item-options .grid .item-filter {
        width: var(--col-12);
    }
}
.votings-grid .table-card .grid .item-table {
    width: var(--col-12);
    padding: 1rem;
}
@media screen and (min-width: 1200px) {
    .votings-grid .table-card .grid .item-table {
        width: var(--col-9);
    }
}
.votings-grid .summary-icon {
    font-size: var(--card-title-size);
    margin-top: 1rem;
}
.votings-grid .summary-value {
    font-size: var(--card-title-size);
    font-weight: var(--font-bold);
    line-height: 2lh;
}
.votings-grid .summary-text {
    font-size: var(--font-normal);
    margin-top: 0;
}
.votings-grid .table-radiobutton {
    margin: 0.75rem 0;
}
.votings-grid .table-options {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 1.5rem;
}

@media screen and (max-width: 1200px) {
    .votings-grid {
        display: flex;
        flex-direction: column;
    }
}

/** @define loading-page */
.loading-page {
    margin-left: 2rem;
    margin-right: 2rem;
}
.loading-page .loading-page__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 24rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.loading-page .loading-page__text {
    color: var(--secondary-color);
    line-height: 1.5;
}

/** @define about */
.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5rem 2rem;
    padding: var(--p-3);
}
@media screen and (width >= 576px) {
    .about {
        padding-top: var(--p-3) !important;
    }
}
@media screen and (width >= 768px) {
    .about {
        padding-top: 2rem !important;
    }
}
.about .about__logo-container {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    justify-content: center;
    align-items: center;
}
@media (min-width: 768px) {
    .about .about__logo-container {
        flex-direction: row;
    }
}
.about .about__image {
    height: 65px;
}

/** mainmenu */
.mainmenu__image {
    max-height: var(--menu-image-height);
    max-width: 150px;
    width: 100%;
    display: var(--menu-image-display);
}

.mainmenu {
    position: -webkit-sticky;
    position: sticky;
    z-index: 2000;
    top: 0px;
}
.mainmenu .menu-item {
    text-wrap: nowrap;
}
.mainmenu .menu-item:hover {
    -webkit-backdrop-filter: var(--menu-item-hover);
    backdrop-filter: var(--menu-item-hover);
}
.mainmenu .p-menubar {
    height: var(--menu-height);
    border: var(--card-border);
    padding: 0.25rem var(--body-side-padding);
    background-color: white;
    color: var(--text-color);
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
}
@media screen and (max-width: 768px) {
    .mainmenu .p-menubar {
        padding: 0.5rem 1rem;
    }
}
.mainmenu .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    color: var(--text-color);
    padding: 0.5rem 0.75rem;
}
.mainmenu .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-menuitem-text,
.mainmenu .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon,
.mainmenu .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: var(--text-color);
}
.mainmenu .p-menubar .mainmenu__button {
    text-wrap: nowrap;
    border: 0;
    display: inline-flex;
    justify-content: center;
    max-width: 450px;
    min-height: 40px;
    overflow: hidden;
    padding: 0px 20px;
    text-align: center;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    vertical-align: middle;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.06), 0px 0px 4px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12);
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    border-radius: 50px;
}
.mainmenu .p-menubar .mainmenu__button:hover {
    -webkit-filter: brightness(110%);
    filter: brightness(110%);
}
.mainmenu .p-menubar .p-menubar-button {
    display: none;
}
.mainmenu .p-menubar .p-menubar-end {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 1390px) {

    .mainmenu .p-menuitem-link {
        padding-left: 0.4rem !important;
        padding-right: 0.4rem !important;
    }
    
    .mainmenu .p-menuitem-link .pi {
        display: none !important;
    }

    .mainmenu .p-menubar-end > button {
        padding: 0 10px !important;
    }

    .p-menubar {
        position: inherit;
        position: relative;
        padding: 0.25rem 2rem;
    }
    .p-menubar .p-menubar-root-list .p-menuitem {
        width: var(--col-12);
        position: static;
    }
    .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
        display: flex;
        flex-direction: column;
        top: 100%;
        left: 0;
        z-index: 1;
    }
}
@media screen and (max-width: 1200px) {
    .p-menubar-root-list {
        display: none;
    }
}
.menu__link-active > span {
    color: var(--primary-color) !important;
    font-weight: var(--font-semibold);
}

.sidemenu__title {
    color: var(--primary-color);
}

.sidemenu__nav-link-active {
    color: var(--primary-color) !important;
    font-weight: var(--font-semibold);
}

.sidemenu__nav-flex {
    display: flex;
    flex-direction: column;
}

.hamburger-icon {
    width: 2rem;
    height: 2rem;
    padding: 1rem 0.5rem;
    justify-content: center;
    color: #6c757d;
    border-radius: 50%;
    vertical-align: middle;
}

.hamburger-icon:hover {
    border: 0 none;
    color: #343a40;
    background: #e9ecef;
    -webkit-transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    -moz-transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    -ms-transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    -o-transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

/** Sidebar */
.p-sidebar-mask {
    z-index: 3000 !important;
}

.p-sidebar .p-sidebar-content nav a {
    color: var(--text-color);
    padding: 16px 20px;
    transition: box-shadow 0.2s;
    height: calc(100% + 2px);
    border-radius: 6px;
}
.p-sidebar .p-sidebar-content nav a:hover {
    -webkit-backdrop-filter: var(--menu-item-hover);
    backdrop-filter: var(--menu-item-hover);
}
.p-sidebar .p-sidebar-content {
    overflow-y: hidden;
}

/** Accordion */
.p-accordion .p-accordion-header .p-accordion-header-link {
    background: white;
    border: none;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    border: 1px solid #dee2e6;
    background-color: white;
}
.p-accordion .p-accordion-header-text {
    width: var(--col-12);
    padding: 0 1rem;
}
@media screen and (min-width: 1200px) {
    .p-accordion .p-accordion-header-text {
        font-size: 20px;
    }
}

/** Buttons */
.p-button.p-button-rounded:has(.p-button-icon) {
    width: 2rem;
    height: 2rem;
    padding: 1.1rem;
}

.p-button.p-button-text {
    color: var(--primary-color);
}

.switch-button {
    background: var(--primary-color);
    border-color: var(--primary-color);
    height: 2rem;
    width: 2rem;
    padding: 0;
    margin: 0.5rem 0rem;
}

.switch-button:is(.p-button-outlined) {
    color: var(--primary-color);
}

/** Card */
.p-card {
    padding: var(--card-padding);
    border-radius: var(--card-radius);
    border: var(--card-border);
    box-shadow: none;
    color: var(--text-color);
    background-color: var(--card-background-color);
}
.p-card .p-card-footer {
    text-align: right;
    padding: var(--card-footer-padding);
}
.p-card .p-card-footer a {
    color: var(--primary-color);
    white-space: nowrap;
    display:inline-flex;
    justify-content: end;
    align-items: center;
}
.p-card .p-card-body {
    padding: var(--card-body-padding);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.p-card .p-card-body .p-card-title {
    color: var(--primary-color);
    font-size: var(--card-title-size);
    margin: 0;
}
.p-card .p-card-content {
    padding: 0;
}
@media screen and (min-width: 1200px) {
    .p-card .p-card-content .card-content {
        padding: 1rem 2rem;
    }
}
.p-card .card-title-w-btn {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

/** Toolbar */
.p-toolbar {
    background-color: #f8f9fa;
    border: var(--card-border);
    padding: var(--card-padding);
    margin: 0.95rem;
    -webkit-border-radius: var(--card-radius);
    -moz-border-radius: var(--card-radius);
    -ms-border-radius: var(--card-radius);
    -o-border-radius: var(--card-radius);
    border-radius: var(--card-radius);
}
.p-toolbar .p-toolbar-group-left {
    display: flex;
    flex-wrap: wrap;
}
.p-toolbar .p-toolbar-group-left > * {
    margin: var(--p-2);
    text-wrap: balance;
    text-align: center;
}

/** Tooltip */
.p-tooltip-text {
    max-width: 25rem;
    min-width: 5rem;
}

/** Overlay panel */
.p-overlaypanel .p-overlaypanel-content {
    padding: 0rem;
}

/** Tabview */
.p-tabview .p-tabview-nav-btn.p-link {
    width: 1.75rem;
    box-shadow: var(--card-hover-shadow);
    color: var(--primary-color);
}
.p-tabview .p-tabview-nav {
    border: 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 0.75rem 0.5rem;
    font-weight: 500;
    color: var(--secondary-color);
    display: flex;
    justify-content: center;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    text-shadow: var(--text-hover-shadow);
    border-color: var(--primary-color);
    color: var(--primary-color);
}
.p-tabview .p-tabview-nav .p-tabview-ink-bar {
    background-color: var(--primary-color);
}
.p-tabview .p-tabview-panels:has(.scrollpanel-wrapper) {
    padding: 0.25rem;
}

.p-progressbar .p-progressbar-label {
    font-weight: var(--font-semibold);
}
.p-progressbar .p-progressbar-value {
    background-color: var(--primary-color);
    min-width: 25px;
}

/** Datatable */
.p-datatable .p-paginator-bottom {
    color: var(--text-color);
    -webkit-user-select: none;
    user-select: none;
}
.p-datatable .p-paginator-bottom {
    border: none !important;
}
.p-datatable .description-cell {
    min-height: 3lh;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
@media screen and (max-width: 768px) {
    .p-datatable .description-cell {
        min-height: 4lh;
    }
}
@media screen and (max-width: 768px) {
    .p-datatable tr > td > .p-column-title {
        padding-right: 10%;
    }
}

.info-list {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 1.5rem;
}

.info-list > li {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
    .info-list > li {
        flex-direction: row;
    }
}

.representatives-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.representatives-list__item {
    display: flex;
    align-items: baseline;
    padding: 0.5rem;
    flex-direction: column;
}

.representatives-list-title {
    width: var(--col-6);
    margin-left: 0.5rem;
    font-weight: var(--font-semibold);
}
@media screen and (min-width: 768px) {
    .representatives-list-title {
        width: var(--col-3);
    }
}

.representatives-list__grid {
    list-style: none;
    margin-top: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.5rem;
    margin-left: -1.5rem;
}

.representatives-list__order {
    width: var(--col-12);
    order: 1;
}
@media screen and (min-width: 768px) {
    .representatives-list__order {
        order: 0;
    }
}

.representatives-list-cell {
    width: var(--col-12);
    flex: 0 0 auto;
    padding: var(--p-2);
    padding-right: 1rem;
}
@media screen and (min-width: 576px) {
    .representatives-list-cell {
        width: var(--col-6);
    }
}
@media screen and (min-width: 992px) {
    .representatives-list-cell {
        width: var(--col-4);
    }
}

.p-fieldset {
    border: none;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
    padding: 0;
}

.p-fieldset .p-fieldset-legend {
    padding: 1.25rem;
    border: none;
    color: var(--secondary-color);
    font-weight: normal;
    border-radius: 6px;
    background-color: white;
}

.p-fieldset .p-fieldset-legend:hover {
    background-color: white;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
    background-color: white;
}

.p-fieldset .p-fieldset-content {
    padding: 0.25rem;
}

.p-chip {
    vertical-align: middle;
    margin-bottom: 10px;
    font-weight: var(--font-semibold);
}

h1 > .p-chip {
    margin-left: 1rem;
}
.representative-title {
    display:flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}
.representative-title .p-chip {
    margin:0;
}
.party-detail .p-toolbar .p-toolbar-group-left > *{
    text-align: left;
}

.representative-grid {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(3, auto);
    gap: var(--layout-gap);
    /** row 2 */
    /** row 3 */
}
@media screen and (min-width: 768px) {
    .representative-grid .grid {
        justify-content: space-evenly;
    }
}
@media screen and (max-width: 1200px) {
    .representative-grid {
        display: flex;
        flex-direction: column;
    }
}
.representative-grid .info-card {
    grid-area: 1/1/1/7;
}
.representative-grid .attendance-card {
    grid-area: 2/5/2/9;
}
.representative-grid .votings-card {
    grid-area: 2/1/2/5;
}
.representative-grid .activity-card {
    grid-area: 3/1/3/9;
}
.representative-grid .activity-card .p-datatable {
    margin-top: 2rem;
}

/** row 1 */
.parties-card {
    grid-area: 1/7/1/9;
}
.parties-card .p-card-body {
    justify-content: normal;
}

.lng-selection .p-dropdown-label {
    padding: 0;
    text-align: center;
}

div.lng-selection.p-dropdown {
    width: 35px !important;
    margin-left: 1rem;
}

.lng-selection .p-dropdown-trigger {
    display: none;
}

.p-dropdown-panel {
    z-index: 3000 !important;
}

.p-dropdown {
    width: 230px;
}

.custom-table-button {
    padding: 0.25rem 0.75rem;
}

.meeting-detail {
    /** row 1 */
    /** row 2 */
}
.meeting-detail .meeting-grid {
    display: flex;
    flex-direction: column;
    gap: var(--layout-gap);
}

@media screen and (min-width: 768px) {
    .meeting-detail .grid {
        justify-content: space-evenly;
    }
}
.meeting-detail .meeting-info__card .grid div:last-child a {
    display: flex;
    justify-content: end;
    align-items: center;
}
.meeting-detail .meeting-attendance__card .grid {
    justify-content: space-between;
}
.meeting-detail .meeting-attendance__card .grid .item-chart,
.meeting-detail .meeting-attendance__card .grid .item-list {
    padding: 1rem;
    width: var(--col-6);
}
@media screen and (max-width: 1200px) {
    .meeting-detail .meeting-attendance__card .grid .item-chart,
    .meeting-detail .meeting-attendance__card .grid .item-list {
        width: var(--col-12);
    }
}

/** page @hlasovani/id */
.voting-detail .voting-detail-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    gap: var(--layout-gap);
}
.voting-detail .voting-detail-grid .info-card {
    grid-area: 1/1/1/1;
}
.voting-detail-title {
    display:flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}
.voting-detail-title .p-chip {
    margin:0 var(--grid-gutter);
}
@media screen and (min-width: 768px) {
    .voting-detail-title {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }
    .voting-detail .voting-detail-grid .info-card .grid {
        justify-content: space-evenly;
    }
}
.voting-detail .voting-detail-grid .info-card .grid > div {
    padding: var(--p-2);
}
.voting-detail .voting-detail-grid .votings-summary-card {
    grid-area: 2/1/2/1;
}
.voting-detail .voting-detail-grid .votings-summary-card .grid .item-chart,
.voting-detail .voting-detail-grid .votings-summary-card .grid .item-list {
    padding: 1rem;
    width: var(--col-6);
}
@media screen and (max-width: 1200px) {
    .voting-detail .voting-detail-grid .votings-summary-card .grid .item-chart,
    .voting-detail .voting-detail-grid .votings-summary-card .grid .item-list {
        width: var(--col-12);
    }
}

/** page @zasedani */
.meetings .meetings-list .item-card {
    width: 100%;
}
.meetings .p-toolbar{
    margin: 0;
    justify-content: center;
}

.meetings .meetings-list {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-template-rows: auto;
    gap: calc(var(--grid-gutter) * 2);
    margin-top: var(--layout-gap);
}

@media screen and (min-width: 768px) {
    .meetings .meetings-list {
        grid-template-columns: repeat(2,1fr);
    }
    .meetings .p-toolbar{
        justify-content: start;
    }
}
@media screen and (min-width: 992px) {
    .meetings .meetings-list {
        grid-template-columns: repeat(3,1fr);
    }
}
@media screen and (min-width: 1200px) {
    .meetings .meetings-list {
        grid-template-columns: repeat(5,1fr);
    }
}
.meetings .meetings-list .item-card :first-child {
    flex-grow: 1;
}
.meetings .meetings-list .item-card .p-card-header {
    text-align: center;
}
.meetings .meetings-list .item-card .card-content {
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
}
@media screen and (min-width: 1200px) {
    .meetings .meetings-list .item-card .card-content {
        margin-left: 0;
    }
}

/** page @subjekt/id */
.party-detail .p-toolbar {
    margin: 0 0 var(--layout-gap) 0;
}
.party-detail-title {
    display:flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}
.party-detail-title .p-chip {
    margin:0;
}
.party-detail-grid .info-card .grid > * {
    flex-shrink:1;
}
@media screen and (min-width: 768px) {
    .party-detail-grid .grid{
        justify-content: space-evenly;
    }
}
.party-detail .party-detail-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(3, auto);
    gap: var(--layout-gap);
}
.party-detail-grid .info-card{
    grid-area: 1/1/1/8;
}
.party-detail .party-detail-grid .members-card {
    grid-area: 2/1/2/5;
}
.party-detail .party-detail-grid .votings-summary-card {
    grid-area: 2/1/2/8;
}
.party-detail .party-detail-grid .party-attendance-card {
    grid-area: 2/5/2/8;
}
@media screen and (max-width: 1200px) {
    .party-detail .party-detail-grid {
        display: flex;
        flex-direction: column;
    }
}

/** page @subjekty */
.parties {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-template-rows: auto;
    gap: calc(var(--grid-gutter) * 2);
}
.parties .item-card {
    width: 100%;
}
.parties .item-card > .p-card {
    height:100%;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}
.parties .item-card > .p-card .p-card-body {
    height:auto;
}

@media screen and (min-width: 768px) {
    .parties {
        grid-template-columns: repeat(2,1fr);
    }
}
@media screen and (min-width: 992px) {
    .parties {
        grid-template-columns: repeat(3,1fr);
    }
}
@media screen and (min-width: 1200px) {
    .parties {
        grid-template-columns: repeat(5,1fr);
    }
}
.parties .item-card .p-card-header {
    text-align: center;
}
.parties .item-card .card-content {
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
}
@media screen and (min-width: 1200px) {
    .parties .item-card .card-content {
        margin-left: 0;
    }
}

/** page @slozeni */
.composition .p-toolbar{
    margin: 0;
}
.composition .p-toolbar-group-left{
    justify-content: center;
    gap: calc(var(--grid-gutter) * 2);
    margin-top: var(--grid-gutter)
}
.composition .members-list .grid-item {
    width: var(--col-12);
}
.composition .members-list {
    display: flex;
    flex-wrap: wrap;
    gap: calc(var(--grid-gutter) * 2);
    margin-top: var(--layout-gap);
}

.composition .members-list .grid-item .card-title {
    display: flex;
    flex-direction: column;
    align-items: center;
}
@media screen and (min-width: 572px) {
    .composition .members-list .grid-item .card-title {
        flex-direction: row;
    }
    .composition .p-toolbar-group-left{
        justify-content: start;
        margin-top:0;
    }
}
.composition .members-list .grid-item .representatives-list .representatives-list-cell {
    width: var(--col-12);
}
@media screen and (min-width: 576px) {
    .composition .members-list .grid-item .representatives-list .representatives-list-cell {
        width: var(--col-6);
    }
}
@media screen and (min-width: 992px) {
    .composition .members-list .grid-item .representatives-list .representatives-list-cell {
        width: var(--col-3);
    }
}

/** page @analyzy/dochazka */
.attendance .attendance-grid {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: var(--layout-gap);
}
.attendance .attendance-grid .members-card {
    grid-area: 1/1/2/7;
}
.attendance .attendance-grid .parties-card {
    grid-area: 1/7/1/12;
}
@media screen and (max-width: 1200px) {
    .attendance .attendance-grid {
        display: flex;
        flex-direction: column;
    }
}

/** page @analyzy/porovnani */
.representative-comparison .dropdowns-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    padding-top: 1.5rem;
    justify-content: center;
}
.representative-comparison .comparison-detail .grid .item-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: var(--col-12);
}
@media screen and (min-width: 768px) {
    .representative-comparison .comparison-detail .grid .item-list {
        width: var(--col-6);
    }
}
.representative-comparison .comparison-detail .voting-comparison {
    padding-top: 0.5rem;
    padding-bottom: 2rem;
}
.representative-comparison .comparison-detail .voting-comparison .dropdown-wrapper {
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding-top: 1.5rem;
}
.representative-comparison .comparison-detail .voting-comparison .tabview-wrapper {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

/** page @not-found */
@media screen and (min-width: 576px) {
    .not-found {
        padding-top: 1rem;
    }
}
@media screen and (min-width: 768px) {
    .not-found {
        adding-top: 2rem;
    }
}
@media screen and (min-width: 992px) {
    .not-found {
        padding-top: 5rem;
    }
}
.not-found .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    margin-bottom: 1rem;
    margin-top: 5em;
}
.not-found .icon {
    font-size: 6rem;
    color: var(--primary-color);
}
.not-found .title {
    font-size: 7rem;
    font-weight: var(--font-bold);
    color: var(--primary-color);
    line-height: 1.5;
}
.not-found .text {
    color: var(--secondary-color);
    font-size: 1.5rem;
    margin-top: 0.5rem;
    text-align: center;
}
.not-found .link {
    color: var(--primary-color);
    font-weight: var(--font-semibold);
}

/** component @SummaryVotesGraph */
.summary-votes .dropdown {
    width: var(--col-12);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem;
}

/** component @UnityLineChart */
.unity-line-chart .selection-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 0rem;
}

.parties-unity {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: var(--layout-gap);
}
.parties-unity .item1 {
    grid-area: 1/1/1/2;
}
.parties-unity .item2 {
    grid-area: 1/2/1/3;
}
@media screen and (max-width: 1200px) {
    .parties-unity {
        display: flex;
        flex-direction: column;
    }
}

/** Helpers */
.flex {
    display: flex;
}

.w5 {
    width: 5%;
}

.w10 {
    width: 10%;
}

.w20 {
    width: 20%;
}

.w25 {
    width: 25%;
}

.w30 {
    width: 30%;
}

.w35 {
    width: 35%;
}

.w40 {
    width: 40%;
}

.w50 {
    width: 50%;
}

.w60 {
    width: 60%;
}

.w70 {
    width: 70%;
}

.w75 {
    width: 75%;
}

.w80 {
    width: 80%;
}

.w100 {
    width: 100%;
}

.minw80 {
    min-width: 80%;
}

.text__success {
    color: var(--success-color);
}

.text__danger {
    color: var(--danger-color);
}

.text__primary {
    color: var(--primary-color);
}

.text__white {
    color: white;
}

.text__black {
    color: black;
}

.text__balance {
    text-wrap: balance;
}

.text__secondary {
    color: var(--secondary-color);
}

.text__bold {
    font-weight: bold;
}

.text__semibold {
    font-weight: var(--font-semibold);
}

.text__normal {
    font-weight: var(--font-normal);
}

.text__xl {
    font-size: 3rem;
}

.text__center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.bg__primary {
    background-color: var(--primary-color);
}

.bg__secondary {
    background-color: var(--secondary-color);
}

.m-2 {
    margin: 0.5rem;
}

.p-2 {
    padding: 0.5rem;
}

.hidden {
    display: none;
}

.hoverable-card:hover {
    box-shadow: var(--card-hover-shadow);
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.pagination-dropdown {
    width: 100px;
}

.pagination-numbers {
    width: 120px;
    text-align: center;
    margin: 0px;
}

.list__unstyled {
    list-style: none;
}

.list__primary {
    list-style-image: url("../assets/p-circle.svg");
}

.list__primary > * {
    padding: var(--p-2);
}

.bullet__primary::before {
    font-family: initial;
    content: url("../assets/p-circle.svg");
    padding-right: 0.5em;
    margin-left: -1em;
}

.bullet__secondary::before {
    font-family: initial;
    content: url("../assets/g-circle.svg");
    padding-right: 0.5em;
    margin-left: -1em;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    margin: var(--grid-gutter);
}

.grid > * {
    flex: 0 0 auto;
    padding: var(--grid-gutter);
}

.nowrap {
    white-space: nowrap;
}

.p-scrollpanel.custom-scroll .p-scrollpanel-bar {
    background-color: var(--primary-color);
    opacity: 0.9;
    transition: background-color 0.2s;
}

.p-scrollpanel.custom-scroll .p-scrollpanel-bar:hover {
    opacity: 1;
}

.scrollpanel-wrapper .custom-scroll {
    height: 10lh;
    width: var(--col-12);
}

.analyses-content {
    padding: 0 2rem;
}

.message-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.border-bottom {
    border-bottom: var(--card-border);
}

.border-top {
    border-top: var(--card-border);
}

.border-left {
    border-left: var(--card-border);
}

.border-right {
    border-right: var(--card-border);
}

.table-view-icon {
    padding: 0;
    margin: 0;
    border: none;
    background-color: white;
    color: var(--primary-color);
}

@media screen and (max-width: 768px) {
    .body--visualization .p-datatable .p-datatable-tbody > tr > td {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start !important;
        width: 100%;
    }
}
