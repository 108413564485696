.heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.heading h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: center;
}

.heading .heading-arrow {
    padding: 1rem;
}

.heading .heading-arrow i {
    font-size: 2rem;
    opacity: .5;
    color: var(--primary-color);
}

@media screen and (max-width: 768px) {

    .heading .heading-arrow {
        display: none;
    }
}