.popup-body {
    display: flex;
    flex-direction: row;
}

.popup-body-left {
    width: 80%
}

.popup-body-attributes {
    padding-left: 1rem;
    margin-top: .5rem;
}

.popup-body-attributes th  {
    text-align: left;
}

.popup-body-attributes tr td:first-of-type  {
    text-align: right;
    padding-right: .5rem;
}

.simple-meter {
    margin-left: .5rem;
}

.popup-body-attributes .popup-body-meter  {
    width: 100px;
}

.popup-body-attributes th::after  {
    content: ":";
    margin-right: .25rem;
}

.popup-body-rank {
    text-align: center;
}

.popup-body-right {
    width: 20%;
    padding-left: 1rem
}

.popup-body-right img {
    width: 100%;
    float: right;
}

@media screen and (max-width: 768px) {

    .popup-body {
        font-size: small;
    }
    
    .popup-body-left {
        width: 66.6%
    }

    .popup-body-right {
        width: 33.3%;
    }

    .popup-body-attributes table {
        display: flex;
        flex-direction: column;
    }

    .popup-body-attributes tr {
        padding-top: .5rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
    }

    .popup-body-attributes th {
        width: 100%;
    }

    .popup-body-attributes th, .popup-body-attributes td {
        text-align: left;
    }

    /*.popup-body-attributes tr {
        
    }

    .popup-body-attributes th {
        grid-column: 1/4;
        grid-row: 1/2;
    }

    .popup-body-attributes th, .popup-body-attributes td {
        text-align: left;
    }*/
}

@media screen and (max-width: 576px) {

    .popup-body {
        flex-direction: column-reverse;
    }
    
    .popup-body-left {
        width: 100%;
        margin-top: 1rem;
    }

    .popup-body-right {
        width: 100%;
        padding: 0px;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .popup-body-right img {
        width: 33%;
    }
}

