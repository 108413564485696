.legend {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 0.5rem 1rem;
    font-size: small;
}

.legend-category {
    display: flex;
    flex-direction: row;
    gap: .25rem;
    align-items: center;
    cursor: pointer;
}

.legend-category.disabled .legend-label {
    text-decoration: line-through;
}

.legend-color {
    width: 1rem;
    height: 1rem;
    border-radius: 25%;
}