.regional-map {
    position: relative;
}

.regional-map svg path.polygon {
    fill: lightgray;
    stroke: white;
    cursor: pointer;
}

.regional-map svg circle.capital {
    fill: darkgray;
    stroke: black;
    cursor: pointer;
}

.map-hover-popup {
    /* position should be derived from the mouse position */
    position: fixed;
    padding: .5rem;
    z-index: 1000;
    color: white;
    background-color: #444444;
    border: 1px solid #444444;
    border-radius: .25rem;
    opacity: .9;
}

.regional-map .regional-map-click-marker {
    position: absolute;
    bottom: 1rem;
    right: 0rem;
    color: var(--primary-color);
}