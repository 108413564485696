
.stats-grid {
    padding: 0px var(--card-padding);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
    gap: 2rem;
}

@media screen and (max-width: 768px) {

    .stats-grid {
        flex-direction: column;
        padding: 0;
    }
}