.section {
    width: 100%;
}

.section-content {
    padding-top: 2rem;
    padding-bottom: 16rem;
}

.heading + .section-content {
    padding-top: 6rem;
}

.section-bookmark {
    float: right;
    font-size: 2rem;
    color: lightgray;
    cursor: pointer;
}

.section-bookmark:hover {
    color: var(--primary-color);
}

@media screen and (max-width: 768px) {

    .section-content {
        padding-top: 2rem;
        padding-bottom: 6rem;
    }

    .heading + .section-content {
        padding-top: 2rem;
    }
}