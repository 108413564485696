:root {
	--input-width-default: 250px;
	--input-width-double: calc(var(--input-width-default) * 2 + var(--gap-md));
	--input-width-half: calc(var(--input-width-default) / 2 - var(--gap-md) / 2);
	--gap-sm: 0.5rem;
	--gap-md: 1rem;

	--primereact-primary-color: #6366F1;
	--primereact-success-color: #22C55E;
	--primereact-danger-color: #EF4444;
	--primereact-warning-color: #F59E0B;
	--primereact-table-border-color: #DEE2E6;
	--primereact-input-padding: 0.75rem;
	--primereact-invalid-color: #E24C4C;
	--primereact-input-color: #6C757D;

	--menu-municipality-name-display: none;

	@media screen and (min-width: 768px) {
		--menu-municipality-name-display: flex;
	}
}

/* FORM ELEMENTS */

/* Form container */
.form-container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: start;
	margin-bottom: 0.5rem;
	gap: var(--gap-sm);

	/* Form row */
	.form-row {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		gap: var(--gap-md);
	}

	.form-row--heading {
		justify-content: left;
	}

	.form-row--button {
		justify-content: left;
		align-items: center;
	}

	.form-row--footer {
		justify-content: center;
		font-size: 0.9rem;
	}

	.form-row--message {
		justify-content: center;
	}

	/* Form input */
	.form-input {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		gap: var(--gap-sm);
		flex-basis: var(--input-width-default);

		/* Add input padding */
		.radio-group {
			padding: var(--primereact-input-padding) 0;
		}

		/* Let PrimeReact inputs fill the form input width */
		.p-dropdown,
		.p-calendar {
			width: auto;
		}

		.p-inputnumber,
		.p-password {
			width: auto;

			.p-inputtext {
				width: 100%;
			}
		}

		.p-multiselect {
			width: auto;
			max-width: var(--input-width-double);
		}
	}

	.form-input--long {
		flex-basis: var(--input-width-double);
	}

	.form-input--short {
		flex-basis: var(--input-width-half);
	}

	.form-input--checkbox {
		flex-basis: initial;
		width: auto;
		align-items: center;

		.p-checkbox {
			margin: var(--primereact-input-padding) 0;
		}
	}

	.form-input--readonly {
		/* Override PrimeReact disabled style */
		.p-disabled {
			opacity: 1;
		}

		/* Color: PrimeReact input style */
		.color-display {
			color: #495057;
			padding: var(--primereact-input-padding);
			background-color: #fff;
			border-radius: 6px;
			border: 1px solid #ced4da;
		}
	}

	.form-input__info-text {
		padding: var(--primereact-input-padding) 0;
		font-weight: bold;
		font-size: 1.2rem;
	}
}

.form-container--dialog {
	min-width: 300px;
	max-width: 90vw;

	.form-row {
		.form-input {
			flex-grow: 1;

			.radio-group {
				padding: 0;
			}
		}
	}
}

.form-container--card {
	margin-bottom: 0;

	.form-row {
		.form-input {
			width: 100%;
			flex-grow: 1;
		}
	}

	.form-row--button {
		justify-content: center;
	}
}

/* Label */
.label {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 0.25rem;

	.icon-tooltip {
		margin-left: 0;
	}
}

.label--required {
	font-weight: bold;
}

/* OVERRIDE/EXTEND App.css */

body:has(.body--admin) {
	background-color: #F1F2F1;
}

.body--admin {
	@media screen and (max-width: 768px) {
		/* Default padding for small screens */
		&#body {
			padding: 0.5rem;
		}
	}

	@media screen and (min-width: 1200px) {
		/* Reduced padding for large screens */
		&#body {
			padding: 2rem 4rem 4rem;
		}
	}

	.p-chip {
		margin: 0;
	}

	.p-datatable {
		min-width: max(33vw, 400px);

		@media screen and (max-width: 768px) {
			width: 100%;
		}
	}

	.p-breadcrumb {
		border: none;
		background: none;
		padding: 0;
	}
}

/* Workaround to fix layering in dialogs, because .p-dropdown-panel has z-index set to 3000 !important in App.css
	FIXME: better solution would be to remove it there and rely on PrimeReact's auto layering if possible */
.p-dropdown-panel--zindex-override {
	z-index: 4000 !important;
}

.footer--admin {
	/* min bottom padding from --body-padding */
	height: 0.75rem;

	.footer-wrapper {
		margin-top: 0;
	}
}

.mainmenu,
.p-sidebar {
	.menu-item {
		.menu-item__icon {
			margin-right: 0.25rem;
		}
	}
}

.mainmenu--admin {
	@media screen and (max-width: 768px) {
		.p-menubar {
			padding: 0.5rem;
		}
	}

	@media screen and (min-width: 1200px) {
		.p-menubar {
			padding: 0.5rem 4rem;
		}
	}

	.hamburger-icon {
		display: none;
	}

	/* Similar style to regular menu items for start item link in login/register menu */
	.p-menubar-start {
		.menu-item:hover {
			border-radius: 6px;
		}
	}
}

/* Municipality name text, optional replacement for mainmenu__image  */
.mainmenu__municipality-name {
	display: var(--menu-municipality-name-display);
	font-weight: bold;
	text-transform: uppercase;
	font-size: 1.2rem;
	align-items: center;
	justify-content: center;
	height: var(--menu-image-height);
}

@media screen and (max-width: 1200px) {
	.mainmenu--admin {
		.mainmenu__image,
		.mainmenu__municipality-name {
			display: none;
		}

		.hamburger-icon {
			display: flex;
		}
	}
}

.p-sidebar {
	.mainmenu__image {
		height: var(--menu-image-height);
	}

	.mainmenu__municipality-name {
		justify-content: start;
	}
}

.sidebar-menu {
	&.p-menu {
		width: 100%;
		border: none;
	}
}

/* GENERIC ELEMENTS */

/* Radio group */
.radio-group {
	display: flex;
	flex-wrap: wrap;
	gap: var(--gap-sm);

	.radio-group__item {
		.p-radiobutton {
			margin-right: 0.25rem;
		}
	}
}

/* Divider line modifiers */
.p-divider-solid.p-divider-horizontal.divider--line,
.p-divider-solid.p-divider-vertical.divider--line {
	opacity: 1;
}

.p-divider-solid.p-divider-horizontal.divider--line {
	&::before {
		border-bottom: none;
		border-right: none;
		border-left: none;
		border-top-style: solid;
	}
}

.p-divider-solid.p-divider-vertical.divider--line {
	margin: 0.2rem;

	&::before {
		border-top: none;
		border-right: none;
		border-bottom: none;
		border-left-style: solid;
	}
}

/* Tooltip */
.p-tooltip-text {
	p {
		color: #fff;
		margin-bottom: 0;
		margin-top: 0.25rem;

		&:first-child {
			margin-top: 0;
		}
	}

}

/* Icon tooltip */
.icon-tooltip {
	margin-left: 0.25rem;
}

.icon-tooltip--warning {
	color: var(--danger-color);
}

/* Toasts */
.toast-container {
	top: calc(20px + var(--menu-height));
	z-index: 2000;
}

/* Table cell modifiers */
.table-cell--warning {
	td& {
		border-left: 4px solid var(--danger-color) !important;
	}
}

.table-cell--success {
	td& {
		border-left: 4px solid var(--success-color) !important;
	}
}

.table-cell-border-left {
	td&,
	th& {
		border-left: solid 1px var(--primereact-table-border-color) !important;
	}
}

.table-cell-border-right {
	td&,
	th& {
		border-right: solid 1px var(--primereact-table-border-color) !important;
	}
}

/* Table row modifiers */
.table-row--primary {
	border-left: 4px solid var(--primary-color);

}

.table-row--clickable {
	cursor: pointer;
}

.table-row--bold {
	font-weight: bold;
}

/* PrimeReact chips
	utility classes for chips replicating their PrimeReact button counterparts
*/

.p-chip-success {
	background-color: var(--primereact-success-color);
	color: #fff;
}

.p-chip-primary {
	background-color: var(--primereact-primary-color);
	color: #fff;
}

.p-chip-danger {
	background-color: var(--primereact-danger-color);
	color: #fff;
}

.p-chip-warning {
	background-color: var(--primereact-warning-color);
	color: #fff;
}

.p-chip-outlined {
	background-color: transparent;
	border: 1px solid #495057;

	&.p-chip-success {
		color: var(--primereact-success-color);
		border: 1px solid var(--primereact-success-color);
	}

	&.p-chip-primary {
		color: var(--primereact-primary-color);
		border: 1px solid var(--primereact-primary-color);
	}

	&.p-chip-danger {
		color: var(--primereact-danger-color);
		border: 1px solid var(--danger-color);
	}

	&.p-chip-warning {
		color: var(--primereact-warning-color);
		border: 1px solid var(--primereact-warning-color);
	}
}

.p-chip-hover {
	cursor: pointer;

	&:hover {
		filter: brightness(85%);
	}
}

/* Dialog window text content */
.dialog-text {
	display: flex;
	flex-direction: column;
	gap: var(--gap-sm);
}

.dialog-text__warning {
	display: flex;
	gap: var(--gap-sm);
	align-items: center;
	font-size: 1.25rem;

	i {
		font-size: 2rem;
		color: red;
	}
}

/* Page segment with Messages component */
.page__messages {
	display: flex;
	justify-content: center;

	.p-message {
		margin-top: 0;
	}
}

/* Page segment with buttons and loader */
.page__buttons {
	display: flex;
	justify-content: start;
	align-items: center;
	gap: var(--gap-sm);
	margin-bottom: 0.5rem;

	button {
		height: fit-content;
	}

	.p-progress-spinner {
		margin: 0;
		margin-left: 0.5rem;
		width: 2.5rem;
		height: 2.5rem;
	}
}

/* Error Message component styled like a Messages component child */
.error-message {
	padding: 1.25rem 1.75rem;
	font-weight: bold;

	&.p-inline-message {
		border-left-width: 6px;
	}
}

/* Attribute row - row with a label and a static attribute value */
.attribute-row {
	padding: 0.25rem 0;
}

/* Buttons in a table cell */
.table-buttons {
	display: flex;
	justify-content: end;
	gap: var(--gap-sm);
}


/* Color: text and color preview  */
.color-display {
	display: flex;
	gap: var(--gap-sm);
	align-items: center;

	/* disabled color picker for color display */
	input {
		opacity: 1;
	}
}

/* SketchPicker style */
.sketch-picker {
	border-radius: 6px !important;
	box-shadow: none !important;
	/* Same border as FileInput, different from other inputs. */
	border: 1px solid var(--primereact-table-border-color);

	* {
		box-sizing: initial;
		font-size: 13px !important;
	}

	&.p-invalid {
		border-color: var(--primereact-invalid-color);
	}
}

/* Hack to fix tabindex in modal dialog */
#political-entity-dialog {
	/* Selects alpha input of SketchPicker, that is present in a hidden div. */
	.sketch-picker>div:nth-child(3)>div:nth-child(5) input {
		display: none;
	}
}

/* NextUI TimePicker style */
.time-input {
	div[data-slot="input-wrapper"],
	div[data-slot="inner-wrapper"] {
		width: 100%;
	}

	.time-input__clear {
		cursor: pointer;
		margin-left: auto;
		display: flex;
		align-items: center;
		color: var(--primereact-input-color);
	}
}

/* FileInput for images */
.image-input {
	.p-fileupload-content {
		text-align: center;
		min-height: 120px;

		.p-image {
			img {
				height: var(--menu-image-height);
			}
		}
	}
}

/* TabView style modifier */
.tabview--admin {
	width: 100%;

	&.p-tabview {
		.p-tabview-nav {
			/* Revert to default PrimeReact border */
			border: 1px solid var(--primereact-table-border-color);
			border-width: 0 0 2px 0;

			.p-tabview-nav-link {
				gap: var(--gap-sm);
			}
		}
	}

	.p-tabview-panels {
		padding: 0;
	}
}

/* Card */
.admin-card {
	margin: 0 auto;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12);
	border-radius: 6px;
	background-color: #fff;
	width: 600px;

	&.p-card {
		.p-card-body {
			padding: 0;
		}
	}
}

/* Membership chips */
.membership-chips {
	display: flex;
	flex-direction: column;
	gap: 0.25rem;

	.p-chip {
		width: fit-content;
	}
}

/* Clickable text */
.clickable-text {
	cursor: pointer;

	&:hover {
		color: var(--primereact-primary-color);
	}
}

/* Inline labeled checkbox  */
.labeled-checkbox {
	.p-checkbox {
		margin-right: 0.5rem;
	}
}

/* VOTES */

.member-vote-table {
	.header-text--long {
		word-break: break-word;
	}

	.header-text--short {
		display: none;
	}

	@media screen and (max-width: 992px) {
		.header-text--long {
			display: none;
		}

		.header-text--short {
			display: initial;
		}
	}

	&.p-datatable .p-datatable-thead>tr>th {
		position: sticky;
		top: var(--menu-height);
		z-index: 2001;
	}

	tr:has(>td .p-radiobutton-focused, .p-checkbox-focused) td {
		background-color: var(--gray-200);
	}
}

/* Political entity member votes */

.member-vote-panel {
	padding: 1.25rem;

	.p-panel-content {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		row-gap: 1.25rem;
		column-gap: var(--gap-sm);
	}

	.p-panel-title {
		display: flex;
		gap: var(--gap-sm);
		align-items: center;
	}
}

.member-vote-panel__item {
	display: flex;
	flex-direction: column;
	justify-items: center;
	gap: var(--gap-sm);
	width: calc(33% - var(--gap-sm));

	@media screen and (max-width: 1200px) {
		width: calc(50% - var(--gap-sm));
	}

	@media screen and (max-width: 768px) {
		width: 100%;
	}

	h4 {
		margin: 0;
		color: var(--text-color);
	}

	div {
		display: flex;
		align-items: center;
		gap: var(--gap-sm);
	}
}

/* GENERIC UTILITY CLASSES */

.flex-center {
	display: flex;
	justify-content: center;
}

.flex-vertical-center {
	display: flex;
	align-items: center;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.mb-2 {
	margin-bottom: 0.5rem;
}

.ml-2 {
	margin-left: 0.5rem;
}

.color-success {
	color: var(--primereact-success-color);
}

.break-word {
	word-break: break-word;
}