.menu-start {
    display: flex;
}

.menu-start .menu-start-hamburger {
    display: none;
}

.menu-start .menu-start-logo img {
    width: 150px;
}

.mainmenu .p-menubar-root-list {
    padding-top: .5rem;
    padding-left: .5rem;
}


@media screen and (max-width: 1200px) {

    .menu-start .menu-start-hamburger {
        display: block;
        margin-right: 1rem;
    }
    
}

@media screen and (max-width: 576px) {

    .menu-start .menu-start-logo img {
        width: 100px;
    }

}