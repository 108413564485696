.subject-list {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.subject-list .subject-list-marker {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin: .25rem;
    background-color: "lighgray";
}