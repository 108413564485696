.links-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: small;
    width: 100%;
}

.links-footer-left, .links-footer-right {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: .5rem;
    gap: .5rem;
}

.links-footer-right {
    justify-content: end;
}

.links-footer-item {
    display: flex;
    align-items: center;
}

.links-footer-item.emp {
    color: var(--primary-color);
}

.links-footer-item .links-footer-item-a {
    display: flex;
    align-items: center;
    color: var(--primary-color);
    cursor: pointer;
}

@media screen and (max-width: 992px) {
    .links-footer {
        flex-direction: column;
    }

    .links-footer-left, .links-footer-right {
        justify-content: center;
    }
}

@media screen and (max-width: 576px) {

    .links-footer {
        font-size: x-small;
        line-height: normal;
    }

    .links-footer-left, .links-footer-right {
        margin-top: .25rem;
    }

    .links-footer .pi {
        font-size: x-small;
    }
}