.simple-meter {
    height: .5rem;
    width: 100%;
    background-color: lightgray;
    border-radius: 5px;
}

.simple-meter-val {
    height: 100%;
    display: block;
    background-color: var(--primary-color);
    opacity: .9;
    border-radius: 5px;
}