.popup-body-chart-content {
    font-size: small;
}

.popup-body-chart-content .emp {
    font-size: small;
}

.popup-body-chart-chart {
    width: 100%;
    height: 100%;
    position: relative;
}