.page {
    margin-top: 0;
    padding: var(--body-padding);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.page p {
    font-size: 1.3rem;
    line-height: 1.8;
}

/* section: heading */
.page section:first-of-type .heading h2 {
    font-size: 1.5rem;
}

@media screen and (max-width: 768px) {

    .page {
        padding-left: 1rem;
        padding-right: 1rem
    }

    .page p {
        font-size: 1rem;
        line-height: 1.5;
    }
}

