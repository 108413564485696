
.stats-section {
    padding-top: 2rem;
    padding-bottom: 2rem;
    max-width: 800px;
    min-width: 600px;
    width: 100%;
    flex: 1;
    border: 1px solid lightgray;
    border-radius: 1rem;
    padding: 2rem;
    position: relative;
    padding-bottom: 4rem;
}

.stats-section .stats-section-par {
    font-size: 1rem;
}

.stats-section span.emp {
    font-size: large;
    color: var(--primary-color)
}

.stats-section strong {
    font-weight: bold;
    font-size: larger;
}

.stats-section .stats-map {
    display: flex;
    justify-content: center;
}

.stats-section .regional-choropleth,
.stats-section :not(.regional-choropleth) > .regional-map {
    width: 80%;
}

.stats-section svg * {
    transition: all ease .5s
}

.stats-section p {
    text-align: justify;
    font-size: 1rem !important;
}

.stats-section h3 {
    font-size: 1.5rem;
}

.stats-section .stats-detail-button {
    margin-top: 1.5rem;
    display: flex;
    justify-content: flex-end;
}

.stats-section .stats-detail-button a {
    display: flex;
    align-items: center;
}

.stats-section-footer {
    margin-top: 1rem;
    position: absolute;
    bottom: 1rem;
    width: calc(100% - 4rem);
}

@media screen and (max-width: 992px) {

    .stats-section {
        padding-bottom: 2rem;
    }

    .stats-section-footer {
        position: relative;
        width: 100%;
        bottom: 0;
    }
}

@media screen and (max-width: 768px) {
    
    .stats-section {
        padding: 0;
        padding-bottom: 1rem;
        max-width: none;
        min-width: 0;
        border: none;
        border-bottom: 1px solid lightgray;
    }

    .stats-section:last-of-type {
        border-bottom: none;
    }
}

@media screen and (max-width: 576px) {

    .stats-section .regional-choropleth,
    .stats-section :not(.regional-choropleth) > .regional-map {
        width: 100%;
    }
}