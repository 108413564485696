.municipality-card {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    -webkit-border-radius: var(--border-radius);
    -moz-border-radius: var(--border-radius);
    -ms-border-radius: var(--border-radius);
    -o-border-radius: var(--border-radius);
    border-radius: var(--border-radius);
    border: var(--card-border);
    cursor: pointer;
    flex-grow: 1;
}

.municipality-card .municipality-card-wrapper {
    display: flex;
    width: auto;
    height: auto;
}

.municipality-card .municipality-card-wrapper:hover h2 {
    filter: brightness(100%);
    -webkit-filter: brightness(100%);
}

.municipality-card .municipality-card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 10rem;
}

.municipality-card .municipality-card-logo {
    width: 200px;
}

.municipality-card .municipality-card-text {
    margin-top: 1rem;
    text-align: center;
    width: 200px;
}

.municipality-card.municipality-card-incomplete{
    border-style: dashed;
    border-width: 2px;
}

/*.municipality-card.municipality-card-incomplete img {
    filter: grayscale(80%);
    opacity: 0.5;
}*/

@media screen and (max-width: 576px) {
    .municipality-card .municipality-card-content {
        height: 7rem;
    }

    .municipality-card .municipality-card-logo {
        width: 150px;
    }
}