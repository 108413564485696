.main-footer {
    background-color: aliceblue;
    padding: 4rem 0;
}

.main-footer-wrapper {
    max-width: 1200px;
    margin:auto;
    padding: 0 1rem;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-template-areas: "logo projects pages contact" "note note note note";

    gap: 1rem;
}

.main-footer-logo {
    padding-top: 1rem;
    grid-area: logo;
}

.main-footer-logo p {
    color: var(--primary-color);
    padding-left: 1rem;
}

.main-footer-logo img {
    height: 3rem;
}

.main-footer-projects {
    grid-area: projects;
}

.main-footer-pages {
    grid-area: pages;
}

.main-footer-contacts {
    grid-area: contact;
}

.main-footer-contacts img {
    height: 3rem;
}

.main-footer-contacts .pi {
    margin-left: .5rem;
}

.main-footer-note {
    grid-area: note;
    text-align: center;
    color: var(--secondary-color);
}

.main-footer-note > div {
    margin-bottom: 1rem;
}

.main-footer ul {
    list-style-type: none;
    padding: 0;
}

.main-footer ul li:before {
    content: "→ ";
    color: var(--secondary-color)
}

@media screen and (max-width: 768px) {
    .main-footer-wrapper {
        text-align: center;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "logo projects" "contact pages" "note note";
    }
    
}

@media screen and (max-width: 576px) {
    .main-footer-wrapper {
        text-align: center;
        grid-template-columns: 1fr;
        grid-template-areas: "logo" "projects" "pages" "contact" "note";
    }
    
}